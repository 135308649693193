export const projectsList = [
  {
    id: 1,
    name: 'redditMini',
    description: 'A simplified version of Reddit.com.',
    github: 'https://github.com/sashacozub/reddit-mini',
    demo: 'https://reddit-mini-sc.netlify.app/',
    technologies: ['react', 'redux', 'router', 'javascript'],
  },
  {
    id: 2,
    name: 'Cocktail Finder',
    description: 'A website for finding cocktail recipes.',
    github: 'https://github.com/sashacozub/cocktail-finder',
    demo: 'https://cocktail-finder-sc.netlify.app/',
    technologies: ['react', 'javascript', 'css'],
  },
  {
    id: 3,
    name: 'Weather Application',
    description: 'An app for getting weather information.',
    github: 'https://github.com/sashacozub/weather_application',
    demo: 'https://weather-application-five-kohl.vercel.app/',
    technologies: ['nextjs', 'javascript', 'tailwindcss'],
  },
  {
    id: 4,
    name: 'Cities of Norway',
    description: 'A website for finding photos of Norwegian cities.',
    github: 'https://github.com/sashacozub/cities-of-norway',
    demo: 'https://cities-of-norway-sc.netlify.app/',
    technologies: ['javascript', 'sass', 'html'],
  },
  {
    id: 5,
    name: 'To-Do App',
    description: 'A simple To-Do app.',
    github: 'https://github.com/sashacozub/todo-app',
    demo: 'https://todo-sc.netlify.app/',
    technologies: ['javascript', 'sass', 'html'],
  },
  {
    id: 6,
    name: 'Calculator',
    description: 'Yes. Another calculator. Vice City inspired.',
    github: 'https://github.com/sashacozub/calculator',
    demo: 'https://calculator-sc.netlify.app',
    technologies: ['javascript', 'sass', 'html'],
  },
  {
    id: 7,
    name: 'sashacozub.com',
    description: 'The source of this website.',
    github: 'https://github.com/sashacozub/sashacozub.com',
    demo: '',
    technologies: ['react', 'router', 'css'],
  },
  {
    id: 8,
    name: 'Find the Hat',
    description: 'A NodeJS game. You need to find your hat.',
    github: 'https://github.com/sashacozub/find-the-hat',
    demo: '',
    technologies: ['nodejs'],
  },
];
